






















































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PopoverInput from '@/components/popover-input.vue'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import LsUpload from '@/components/ls-upload.vue'
import FileItemOpticalFieldTwo from './file-item-optical-field-2d.vue'
import FileItemOpticalFieldThree from './file-item-optical-field-3d.vue'
import {
  apiFileAddCate,
  apiFileEditCate,
  apiFileDelCate,
  apiFileList,
  apiFileListCate,
  apiFileDel,
  apiFileMove,
  apiFileRename,
} from '@/api/shop'
import { RequestPaging } from '@/utils/util'

import config from '@/config'
import { apiSaveOpticalFieldMaterial } from '@/api/imagetru3d'
@Component({
  components: {
    LsPagination,
    PopoverInput,
    LsDialog,
    LsUpload,
    FileItemOpticalFieldTwo,
    FileItemOpticalFieldThree,
  },
})
export default class MaterialOpticalField extends Vue {
  // @Prop({ default: 'image' }) type!: 'image' | 'video' | 'file'
  @Prop({ default: 'image' }) type!:
    | 'image'
    | 'video'
    | 'interaction'
    | 'opticalField'
    | 'boxName'

  @Prop({ default: '100' }) size!: string
  @Prop({ default: 'popup' }) mode!: 'pages' | 'popup'
  @Prop({ default: 20 }) pageSize!: number
  @Prop() limit!: number
  // data
  name = ''
  moveId = 0
  checkAll = false
  isIndeterminate = false
  currentId = ''
  fileList = []
  groupeLists: any[] = []
  pager = new RequestPaging({ size: this.pageSize })
  selectList: any[] = []

  // S 文件上传

  $refs!: {
    opticalFieldForm: any
    opticalFieldDialog: any
    upload2D: any
    upload3D: any
  }

  // 2d 素材对象
  data2DMaterail = {
    cid: '',
    id: '',
    name: '',
    type: '',
    uri: '',
    url: '',
  }

  // 3d 素材对象
  data3DMaterail = {
    cid: '',
    id: '',
    name: '',
    type: '',
    uri: '',
    url: '',
  }

  // 2d 素材上传成功时的回调
  handleSuccessFor2D(response: any, file: any, fileList: any[]) {
    console.log(response)
    this.data2DMaterail = response.data
  }

  // 3d 素材上传成功时的回调
  handleSuccessFor3D(response: any, file: any, fileList: any[]) {
    console.log(response)
    this.data3DMaterail = response.data
  }

  // 控制对话框是否显示的变量
  opticalFieldDialogVisible = false

  // 版本号，用于调用接口
  // headers
  version = config.version

  // 上传所调用的接口
  action = `${config.baseURL}/adminapi/upload/lightFile`

  // 2d 素材上传失败时的回调
  handleErrorFor2D(err: any, file: any) {
    this.$message.error(`${file.name}文件上传失败`)
    this.$refs.upload2D.abort()
  }

  // 3d 素材上传失败时的回调
  handleErrorFor3D(err: any, file: any) {
    this.$message.error(`${file.name}文件上传失败`)
    this.$refs.upload3D.abort()
  }

  // 文件超出个数限制时的回调
  handleExceed() {
    this.$message.warning('当前限制上传 1 个文件，现已超出上传上限，请重新上传')
  }

  handleRemoveFor2D() {
    this.data2DMaterail = {
      cid: '',
      id: '',
      name: '',
      type: '',
      uri: '',
      url: '',
    }
  }

  handleRemoveFor3D() {
    this.data3DMaterail = {
      cid: '',
      id: '',
      name: '',
      type: '',
      uri: '',
      url: '',
    }
  }

  // 删除文件前的回调
  beforeRemove(file: any) {
    return this.$confirm(`确定移除 ${file.name}？`)
  }

  // 打开对话框,同时初始化素材对象
  openOpticalFieldDialog() {
    this.opticalFieldDialogVisible = true
    console.log(this.data2DMaterail)
    this.data2DMaterail = {
      cid: '',
      id: '',
      name: '',
      type: '',
      uri: '',
      url: '',
    }

    this.data3DMaterail = {
      cid: '',
      id: '',
      name: '',
      type: '',
      uri: '',
      url: '',
    }
  }

  // 关闭对话框
  closeOpticalFieldDialog() {
    this.opticalFieldDialogVisible = false
  }

  // 保存文件上传所调用的接口
  saveOpticalFieldMaterial() {
    console.log(this.data2DMaterail)
    console.log(this.data3DMaterail)
    //
    const materailData = {
      img: this.data2DMaterail.uri,
      light_uri: this.data3DMaterail.uri,
      name: this.data2DMaterail.name,
    }

    console.log(materailData)

    apiSaveOpticalFieldMaterial({
      materailData,
    })
      .then((res) => {
        this.getData()
        this.closeOpticalFieldDialog()
      })
      .catch((error) => {
        this.$message.error('必须同时选取2D 素材和3D 素材，请重新选取')
      })

    // this.$refs.opticalFieldForm.validate((valid: boolean, object: any) => {
    //   if (valid) {
    //     apiSaveOpticalFieldMaterial({
    //       materailData,
    //     }).then((res) => {
    //       this.getData()
    //       console.log('a')
    //       this.$refs.opticalFieldDialog.close()
    //       console.log('b')
    //     })
    //   } else {
    //     return false
    //   }
    // })
  }

  // E

  // 获取每个标签页的类型别名
  get typeValue () {
    switch (this.type) {
      case 'image':
        return 10
      case 'video':
        return 20
      case 'interaction':
        return 30
      case 'opticalField':
        return 40
      case 'boxName':
        return 50
    }
  }

  @Watch('selectList')
  selectListChange(val: any[]) {
    this.$emit('change', val)
    if (val.length == this.pager.lists.length && val.length !== 0) {
      this.isIndeterminate = false
      this.checkAll = true
      return
    }
    if (val.length > 0) {
      this.isIndeterminate = true
    } else {
      this.checkAll = false
      this.isIndeterminate = false
    }
  }

  //
  get selectStatus() {
    return (id: any) => this.selectList.find((item) => item.id == id)
  }

  // 异步获取分组列表
  async getGroupeList() {
    const res = await apiFileListCate({
      type: this.typeValue,
      page_type: 1,
    })
    const item = [
      {
        name: '全部',
        id: '',
      },
      {
        name: '未分组',
        id: 0,
      },
    ]
    this.groupeLists = res?.lists
    this.groupeLists.unshift(...item)
  }

  //
  getList(): void {
    if (this.mode == 'pages') {
      this.clearSelectList()
    }
    console.log(this.typeValue)
    this.pager.request({
      callback: apiFileList,
      params: {
        type: this.typeValue,
        cid: this.currentId,
        name: this.name,
      },
    })
  }

  // 添加分组
  addGroupe(value: string, id: number) {
    if (!value) return this.$message.error('请输入分组名称')
    apiFileAddCate({
      type: this.typeValue,
      pid: id | 0,
      name: value
    }).then((res) => {
      this.getData()
    })
  }

  // 编辑分组（命名分组）
  editGroupe(value: string, id: number) {
    apiFileEditCate({
      name: value,
      id,
    }).then((res) => {
      this.getData()
    })
  }

  // 删除分组
  delGroupe(id: number) {
    apiFileDelCate({
      id,
    }).then((res) => {
      this.getData()
    })
  }

  // 异步获取分组列表中的分组数据
  async getData() {
    this.pager.loading = true
    await this.getGroupeList()
    this.pager.loading = false
    this.getList()
  }

  //
  currentChange({ id }: any) {
    this.currentId = id
    this.getList()
  }

  // 选中文件 / 取消选中文件
  selectFile(item: any) {
    let index = this.selectList.findIndex((items: any) => items.id == item.id)
    if (index != -1) {
      this.selectList.splice(index, 1)
      return
    }
    if (this.mode == 'popup' && this.selectList.length == this.limit) {
      if (this.limit == 1) {
        this.selectList = []
        this.selectList.push(item)
        return
      }
      this.$message.warning('已达到选择上限')
      return
    }
    this.selectList.push(item)
  }

  // 当页全选 / 取消当页全选
  selectAll(value: boolean) {
    this.isIndeterminate = false
    if (value) {
      this.selectList = [...this.pager.lists]
      return
    }
    this.clearSelectList()
  }

  // 批量删除
  batchFileDel(id: any[]) {
    let ids = id ? id : this.selectList.map((item) => item.id)
    apiFileDel({
      ids,
    }).then((res) => {
      this.getList()
    })
  }

  // 批量移动
  batchFileMove() {
    let ids = this.selectList.map((item) => item.id)
    apiFileMove({
      ids,
      cid: this.moveId,
    }).then((res) => {
      this.moveId = 0
      this.getList()
    })
  }

  // 重命名
  fileRename(value: string, id: number) {
    apiFileRename({
      id,
      name: value,
    }).then(() => {
      this.getList()
    })
  }

  // 删除图片
  delImage(id: number) {
    this.selectList = this.selectList.filter((item) => item.id != id)
  }

  // 取消当页全选
  clearSelectList() {
    this.selectList = []
  }

  created() {
    this.getData()
  }
}
