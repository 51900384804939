































import { RequestPaging } from '@/utils/util'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialItem from '@/components/material-select/material.vue'
import MaterialItemOpticalField from '@/components/material-select/material-optical-field.vue'
@Component({
  components: {
    MaterialItem,
    MaterialItemOpticalField,
  },
})
export default class Material extends Vue {
  materialType = ''
  activeName = 'image'
  paneLists = [
    {
      label: '图片',
      name: 'image',
      type: 'image',
    },
    {
      label: '视频',
      name: 'video',
      type: 'video',
    },
    {
      label: '交互',
      name: 'interaction',
      type: 'interaction',
    },
    {
      label: '光场',
      name: 'opticalField',
      type: 'opticalField',
    },
    {
      label: '台历封面图',
      name: 'boxName',
      type: 'boxName',
    },
  ]
  pager = new RequestPaging()

  getList() {}

  handleClick(tab: any) {
     // console.log(tab.name)
    this.materialType = tab.name == 'opticalField' ? '1' : '0'
    //console.log( this.materialType)
  }
}
